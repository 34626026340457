@import "./flag.scss";

.react-tel-input {
  .flag-dropdown {
    max-width: 70px;
    height: calc(1.5em + 0.75rem - 1px);
    padding: 0;
    &:hover,
    &:focus {
      cursor: pointer;
    }
    &.open {
      z-index: 2;
    }
  }
  input[disabled] {
    & + .flag-dropdown {
      &:hover {
        cursor: default;
        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }
  .selected-flag {
    outline: none;
    position: relative;
    margin-left: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      .arrow {
        background-color: #000;
      }
    }
    .arrow {
      position: relative;
      left: 39px;
      top: 3px;
      mask: url("/assets/icons/dropdown-arrow.svg");
      background-color: #737373;
      height: 7px;
      width: 12px;
      background-repeat: no-repeat;
    }
  }
  .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: white;
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    .flag {
      display: inline-block;
    }
    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }
    .country {
      padding: 7px 9px;
      outline: none;
      .dial-code {
        color: #6b6b6b;
      }
      &:hover {
        background-color: #f1f1f1;
      }
      &.highlight {
        background-color: #f1f1f1;
      }
    }
    .flag {
      margin-right: 7px;
      margin-top: 2px;
    }
    .country-name {
      margin-right: 6px;
    }
    .search {
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 10px 0 6px 10px;
    }
    .search-emoji {
      font-size: 15px;
    }
    .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;
    }
    .no-entries-message {
      display: none;
    }
  }
  .invalid-number-message {
    display: none;
  }
  .special-label {
    display: none;
  }
}
